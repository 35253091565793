import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { KTSVG } from '../../../helpers';
import { useThemeMode } from '../../../partials';
import { useLayout } from '../../core';
import MetronicDropdown from 'app/components/MetronicDropdown';
import NavConfig from 'app/modules/nav-config/NavConfig';
import { useDataTemplateByOrganisation } from 'app/api/dataTemplate/dataTemplate';
import LIME_LOGO_WHITE from 'app/assets/images/LIME Logo BIAAS White.png';
import LIME_LOGO_BLACK from 'app/assets/images/LIME Logo BIAAS Tag.png';
import AppTooltip from 'app/components/Tooltip/Tooltip';
import Typography from 'app/components/Typography/Typography';
import AccountMenu from 'app/modules/account-menu/AccountMenu';
import useMailSupport from 'app/hooks/useMailSupport';
import useWhiteLabel from 'app/hooks/useWhiteLabel';
import useLimeLogo from 'app/hooks/useLimeLogo';
import useRemoveLimeSupport from 'app/hooks/useRemoveLimeSupport';

const itemClass = 'ms-1 ms-lg-3 ';
const btnClass =
    'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
// const userAvatarClass = 'symbol-35px symbol-md-40px';

const Navbar = () => {
    const { config } = useLayout();
    const params = useParams();
    const navigate = useNavigate();
    const { menuMode } = useThemeMode();
    const openEmailSupport = useMailSupport();
    const whiteLabelOrg = useWhiteLabel();
    const removeLimeLogo = useLimeLogo();
    const removeLimeSupport = useRemoveLimeSupport();

    const btnIconClass = `svg-icon-1 btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-${whiteLabelOrg || 'primary'}`;

    const { data: dataTemplates, isLoading: isDataTemplateLoading } = useDataTemplateByOrganisation(params.organisationId!);

    return (
        <div className='app-navbar flex-shrink-0'>
            {(!whiteLabelOrg && !removeLimeLogo) && <a href='https://www.limeintel.com/' target='_blank' rel='noreferrer'
                className='d-flex align-items-center justify-content-center'>
                <img src={menuMode === 'light' ? LIME_LOGO_BLACK : LIME_LOGO_WHITE} width={125}
                    alt='Lime logo' />
            </a>}
            {!isDataTemplateLoading && dataTemplates?.data.data.length
                ? <button onClick={() => navigate(`/organisation/${params.organisationId}/data-upload`)} className={clsx('app-navbar-item', itemClass, 'bare-button')}>
                    <div className={btnClass}>
                        <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className={btnIconClass} />
                    </div>
                </button>
                : <></>}
            <MetronicDropdown className={clsx('app-navbar-item w-35px h-35px w-md-40px h-md-40px', itemClass)} toggleElement={<AppTooltip placement='bottom' TooltipContent={<Typography>Config</Typography>}>
                <KTSVG path='/media/icons/duotune/coding/cod001.svg' className={btnIconClass} />
            </AppTooltip>}>
                <NavConfig className='p-5 w-300px' />
            </MetronicDropdown>
            {removeLimeSupport && <button onClick={openEmailSupport} className={clsx('app-navbar-item w-35px h-35px w-md-40px h-md-40px', itemClass, 'bare-button')}>
                <AppTooltip placement='bottom' TooltipContent={<Typography>Lime Support</Typography>}>
                    <i className={`fa-solid fa-question ${btnIconClass}`} />
                </AppTooltip>
            </button>}
            <MetronicDropdown className={clsx('app-navbar-item w-35px h-35px w-md-40px h-md-40px', itemClass)} toggleElement={<AppTooltip placement='bottom' TooltipContent={<Typography>Account</Typography>}>
                <KTSVG path='/media/icons/duotune/communication/com006.svg' className={btnIconClass} />
            </AppTooltip>}>
                <AccountMenu />
            </MetronicDropdown>

            <div>
                {config.app?.header?.default?.menu?.display && (
                    <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
                        <div
                            className='btn btn-icon btn-active-color-primary w-35px h-35px'
                            id='kt_app_header_menu_toggle'>
                            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
};

export { Navbar };
