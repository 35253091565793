
import { ReportsType } from 'app/api/reports/types';
import { useAuth } from 'app/modules/auth';

const fourDecimalPlacesFormatter = (params: any) => {
    return params.value.toFixed(4);
};

const yyyymmddFormatter = (params: any) => {
    if (params.value === null) return params.value;

    const parsedDate = typeof params.value === 'string' ? new Date(params.value) : params.value;

    // Check if the parsedDate is a valid date
    if (isNaN(parsedDate.getTime())) {
        throw new Error('Invalid date');
    }

    // Get year, month, and day from parsedDate
    const year = parsedDate.getFullYear();
    const month = ('0' + (parsedDate.getMonth() + 1)).slice(-2); // Months are zero based
    const day = ('0' + parsedDate.getDate()).slice(-2);

    // Return formatted date in yyyy-MM-dd format
    return `${year}-${month}-${day}`;
};

const valueFormatter: any = {
    VOLUME: fourDecimalPlacesFormatter,
    REQUIRED_BY_DATE: yyyymmddFormatter
};

const textAlign: any = {
    VOLUME: 'rightAligned'
};

const useCreateCoreGridColumns = (sheetData: { [key: string]: string | number }, report: ReportsType) => {
    const { currentUser } = useAuth();
    const accessGroup = currentUser?.app_metadata.accessGroup;

    const createdColumns = Object.keys(sheetData || {}).map((name) => {
        let hiddenForAccessGroup: any = [];
        let readonlyForAccessGroup: any = [];

        if (accessGroup) {
            hiddenForAccessGroup = report.accessGroup.columnHidden?.[accessGroup] || [];
            readonlyForAccessGroup = report.accessGroup.columnReadonly?.[accessGroup] || [];
        }

        const reportReadonly = (report.readOnly || []).concat(readonlyForAccessGroup);
        const reportHidden = (report.hidden || []).concat(hiddenForAccessGroup);

        return ({
            field: name,
            width: report.columnWidth ? report.columnWidth[name] : undefined,
            hide: reportHidden.includes(name),
            editable: !reportReadonly.includes(name),
            headerName: report.columnNames ? report.columnNames[name] : name,
            filter: report.filter ? report.filter[name] : 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
                closeOnApply: true
            },
            type: textAlign[name] ? textAlign[name] : undefined,
            valueFormatter: valueFormatter[name] ? valueFormatter[name] : undefined,
            cellEditor: report.cellEditor ? report.cellEditor[name] : 'agTextCellEditor',
            cellEditorPopup: report.cellEditor && report.cellEditor[name] === 'agLargeTextCellEditor',
            cellEditorParams: report.cellEditorParams && report.cellEditorParams[name.toLowerCase()]
        });
    });

    return createdColumns;
};

export default useCreateCoreGridColumns;
