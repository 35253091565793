import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions } from '../makeApiCall';
import { CoreType } from './types';

const GET_CORE_PRODUCTION_PLANNING = 'getCoreProductionPlanning';

const base = 'core';

const createQueryString = (queryParams: { [key: string]: string }) => {
    const queryString = Object.keys(queryParams)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');

    return queryString;
};

export function useGetCoreData(filterType: 'machine' | 'drying' | 'treatmentplant', { ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<CoreType[]>([`${GET_CORE_PRODUCTION_PLANNING}-${filterType}`], `/${base}/spreadsheet?filterType=${filterType}`, { ...options, method: 'GET' });
}

export async function updateCoreData(data: CoreType[], queryParams: { [key: string]: string }) {
    return await makeApiRequest<any>(`/${base}/spreadsheet/update`, 'POST', data, 'application/json', queryParams);
}
