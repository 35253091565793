import { useEffect, useMemo, useState } from 'react';
import Button from 'app/components/Button';
import useToast from 'app/hooks/useToast';
import useConfigStore from 'app/store/userConfig';
import useRightDrawerStore from 'app/store/rightDrawer';
import Typography from 'app/components/Typography';
import { ReportsType } from 'app/api/reports/types';
import { ORIENTATION } from 'app/utils/constants';
import { findChangedFields, updateRightDrawerData } from 'app/utils/helpers';
import AgGrid from './AgGrid';
import useSpreadsheet from '../hooks/useSpreadsheet';
import useCreateICSSColumns from './hooks/useCreateICSSColumns';
import { createICSSFinancialYear, updateICSSClientBudget, useGetICSSClientBudgetData, useGetICSSClientNameOptions } from 'app/api/icss/icss';
import { ICSSClientBudget, ICSSClientBudgetUpdate } from 'app/api/icss/types';

import './spreadsheet-grid-icss.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

type Props = {
    report: ReportsType;
    className?: string;
}

const SpreadsheetICSS = ({ report, className }: Props) => {
    const [freshData, setFreshData] = useState<ICSSClientBudget[]>([]);
    const [isSaving, setIsSaving] = useState(false);

    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();
    const { setNotification } = useToast();
    const { navbarOrientation } = useConfigStore();

    const { data: sheetDataResponse, refetch: refetchClientBudget, isLoading: isSpreadsheetDataLoading } = useGetICSSClientBudgetData(
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            onSuccess: (data) => {
                setFreshData(data.data.data);
            }
        });

    const { data: sheetDataOptionsResponse, refetch: refetchClientNameOptions } = useGetICSSClientNameOptions(
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false
        });

    const { gridApi, pageSize, isSheetUpdated, setIsSheetUpdated, handleGridReady, onPageSizeChanged, onCellValueChanged } = useSpreadsheet(isSpreadsheetDataLoading);

    const sheetData: any = useMemo(() => sheetDataResponse ? JSON.parse(JSON.stringify(sheetDataResponse.data.data)) : [], [sheetDataResponse]);
    const sheetDataOptions: ICSSClientBudget[] = useMemo(() => sheetDataOptionsResponse ? JSON.parse(JSON.stringify(sheetDataOptionsResponse.data.data)) : [], [sheetDataOptionsResponse]);

    const createdColumns = useCreateICSSColumns(sheetData[0] || {}, report);

    const columnNames = useMemo(() => createdColumns, [sheetData, report]);

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <Typography>No Available Actions</Typography>
        }, {
            keyName: 'export',
            isShow: false,
            content: () => <></>
        }, {
            keyName: 'filters',
            isShow: false,
            content: () => <div />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isSheetUpdated, sheetData]);

    const handleSaveSpreadsheet = async () => {
        const updatedValues: any[] = [];

        if (gridApi) {
            gridApi.forEachNode((node) => {
                const updatedValue = node.data;

                updatedValues.push(updatedValue);
            });
        }

        if (sheetData) {
            try {
                setIsSaving(true);
                const changedObjectsAndFields = findChangedFields<ICSSClientBudget>(freshData, updatedValues);

                // transform changed object to correct data structure for backend
                const transformedArray: ICSSClientBudgetUpdate[] = changedObjectsAndFields.flatMap((item: any) => {
                    const clientCode = item.CLIENT_CODE || (sheetDataOptions.length
                        ? sheetDataOptions.find((data) => {
                            return data.CLIENT_NAME === item.CLIENT_NAME;
                        })?.CLIENT_CODE || ''
                        : '');

                    return Object.entries(item.changedFields)
                        .filter(([key]) => !['CLIENT_CODE', 'CLIENT_NAME', 'IS_SAVED'].includes(key)) // Filter out the unwanted fields
                        .map(([year, budgetAmount]): ICSSClientBudgetUpdate => ({
                            CLIENT_CODE: clientCode,
                            FINANCIAL_YEAR: parseInt(year, 10),
                            BUDGET_AMOUNT: budgetAmount as number
                        }));
                });

                await updateICSSClientBudget(transformedArray, report.scheduleName || '');
                refetchClientNameOptions();
                await refetchClientBudget();

                setNotification('Sheet saved', 'success');

                setIsSheetUpdated(false);
            } catch (error: any) {
                if (error.response && error.response.data && error.response.data.message) {
                    setNotification(error.response.data.message, 'error');
                } else {
                    setNotification('Failed to update spreadsheet', 'error');
                }
            }

            setIsSaving(false);
        }
    };

    const handleAddRowGrid = async () => {
        const newData = columnNames.reduce((acc: { [key: string]: any }, column) => {
            if (column.field === 'CLIENT_NAME' || column.field === 'CLIENT_CODE') {
                acc[column.field] = '';
            } else if (column.field === 'IS_SAVED') {
                acc[column.field] = false;
            } else {
                acc[column.field] = 0;
            }

            return acc;
        }, {});

        if (gridApi) {
            gridApi.applyTransaction({ add: [newData] })!;

            gridApi.paginationGoToLastPage();
            setNotification('Added a row', 'success');
        }
    };

    const handleCreateNewFinancialYear = async () => {
        // Extract numeric field names and convert them to numbers
        const fields: number[] = Object.keys(sheetData[0])
            .filter(key => !isNaN(Number(key))) // Filter to keep only numeric keys
            .map(key => Number(key)); // Convert keys to numbers

        // Find the largest value in the array
        const largestValue = Math.max(...fields);

        try {
            setIsSaving(true);
            await createICSSFinancialYear({ newFinancialYear: (largestValue + 1).toString() });
            await refetchClientBudget();
        } catch (error) {
            setNotification('Failed to create a new Financial Year', 'error');
        }

        setIsSaving(false);
    };

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Typography className='w-100 text-left' weight='bolder' size='2x'>{report.reportName}</Typography>
            </div>
            <div className={`spreadsheet-grid-icss card w-100 ${className}`}>
                <div className='grid-control'>
                    <div className='d-flex justify-content-end gap-3' />
                    <div className='d-flex justify-content-end gap-3' >
                        <Button size='sm' onClick={handleAddRowGrid}>Add Row</Button>
                        <Button size='sm' disabled={isSaving} onClick={handleCreateNewFinancialYear}>Create New FY</Button>
                        <Button size='sm' disabled={isSaving} onClick={handleSaveSpreadsheet}>Save</Button>
                    </div>
                </div>
                <div className={`ag-theme-alpine ${navbarOrientation === ORIENTATION.VERTICAL ? 'vertical' : 'horizontal'}`}>
                    <AgGrid
                        pageSize={pageSize}
                        sheetData={sheetData}
                        sheetDataOptions={sheetDataOptions}
                        handleGridReady={handleGridReady}
                        columnDefs={columnNames}
                        onCellValueChanged={onCellValueChanged} />
                    <div className='d-flex align-items-center justify-content-end my-2'>
                        <p className='fw-semibold fs-4 m-0 me-2'>Page Size:</p>
                        <select value={pageSize} onChange={onPageSizeChanged} id='page-size'>
                            <option value='dynamic'>Dynamic</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='300'>300</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetICSS;
