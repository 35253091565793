import React, { useMemo } from 'react';
import { CellValueChangedEvent, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import './ag-grid.scss';
import { HLSHEmployees } from 'app/api/hlsh/types';

type Props = {
    pageSize: string;
    sheetData: HLSHEmployees[];
    columnDefs: { [key: string]: any }[]
    handleGridReady: (params: GridReadyEvent) => void;
    onCellValueChanged: (data: CellValueChangedEvent<HLSHEmployees>) => void;
}

const PINNED_LEFT = ['EMPLOYEE_ID', 'EMPLOYEE_NAME', 'TYPE'];

const AgGrid = ({ pageSize, sheetData, columnDefs, handleGridReady, onCellValueChanged }: Props) => {
    const defaultColDef = useMemo(() => ({
        filter: true,
        sortable: true,
        resizable: true
    }), []);

    const updateCustomComponents = () => {
        const updatedData = [...columnDefs];

        columnDefs.forEach((item, index) => {
            if (PINNED_LEFT.includes(item.field)) {
                updatedData[index] = {
                    ...updatedData[index],
                    pinned: 'left'
                };
            }
        });

        return updatedData;
    };

    return (
        <AgGridReact
            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading spreadsheet...</span>'}
            pagination={true}
            paginationAutoPageSize={pageSize === 'dynamic'}
            suppressMenuHide={true}
            animateRows={true}
            singleClickEdit={true}
            rowSelection='multiple'
            suppressColumnVirtualisation={true}
            stopEditingWhenCellsLoseFocus={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            onGridReady={handleGridReady}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellValueChanged}
            rowData={sheetData}
            columnDefs={updateCustomComponents()} />
    );
};

export default React.memo(AgGrid);
