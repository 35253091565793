import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import './app-datepicker.scss';
import 'react-datepicker/dist/react-datepicker.css';

const AppDatePicker = (props: ReactDatePickerProps) => {
    return (
        <DatePicker {...props} />
    );
};

export default AppDatePicker;
