import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useGetData } from 'app/api/processing/processing';
import { AAAN_STATUS, AAAN_STATUS_MAPPING } from './chartConfigs';
import moment from 'moment';
import './chart.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Chart = () => {
    const { data: rawSheetData } = useGetData({
        db: process.env.REACT_APP_AAAN_DB!,
        schema: process.env.REACT_APP_AAAN_STATUS_CHART_SCHEMA!,
        viewOrTable: process.env.REACT_APP_AAAN_STATUS_CHART_VIEW!
    }, [], {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false
    });

    const parsedData = rawSheetData ? JSON.parse(rawSheetData.data.data) : [];

    const batchId = parsedData.map((item: any) => `${item.BATCH_NAME ? item.BATCH_NAME : `Batch ${item.BATCH_ID.toString()}`} ${moment(item.BATCH_DATE_CREATED_UTC).format('YYYY-MM-DD')}`);

    const createXAxis = () => {
        const billingRecordStatus = parsedData.map((item: any) => {
            return AAAN_STATUS[item.BILLING_RECORD_STATUS];
        });

        Object.values(AAAN_STATUS).forEach((value) => {
            if (!billingRecordStatus.includes(value)) {
                billingRecordStatus.push(value);
            }
        });

        return billingRecordStatus;
    };

    const chartData = {
        labels: batchId,
        datasets: [
            {
                label: 'Billing Record Status',
                data: createXAxis(),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                maxBarThickness: 50
            }
        ]
    };

    const chartOptions = {
        indexAxis: 'y' as const,
        elements: {
            bar: {
                borderWidth: 2
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'right' as const
            },
            title: {
                display: true,
                text: 'Batch Status'
            },
            tooltip: {
                enabled: false
            }
        },
        scales: {
            x: {
                ticks: {
                    callback: function (value: string | number, index: any, ticks: any) {
                        const numberedValue = Number(value) as 1 | 2 | 3;

                        return AAAN_STATUS_MAPPING[numberedValue] || value.toString();
                    },
                    precision: 0
                }
            }

        }
    };

    return (
        <div style={{ width: '100%' }} className='app-chart card '>
            <Bar options={chartOptions}
                data={chartData} />
        </div>
    );
};

export default Chart;
