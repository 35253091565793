import { useParams } from 'react-router-dom';

export const NO_LIME_LOGO = [''];

const useLimeLogo = () => {
    const params = useParams();

    if (!params.organisationId) return false;

    return NO_LIME_LOGO.includes(params.organisationId);
};

export default useLimeLogo;
