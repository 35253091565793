import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions } from '../makeApiCall';
import { HLSHEmployees } from './types';

const GET_HLSH_EMPLOYEES_DATA = 'getHLSHEmployeesData';

export function useGetHLSHEmployeesData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<HLSHEmployees[]>([`${GET_HLSH_EMPLOYEES_DATA}`], '/hlshemployees/spreadsheet', { ...options, method: 'GET' });
}

export async function addHLSHEmployeeTeam(data: {EMPLOYEE_ID: number, TEAM_NAME: string, TYPE: string}) {
    return await makeApiRequest<boolean>('/hlshemployees/spreadsheet/addteam', 'POST', data);
}

export async function deleteHLSHEmployeeTeam(data: {EMPLOYEE_ID: number, TEAM_NAME: string, TYPE: string}) {
    return await makeApiRequest<boolean>('/hlshemployees/spreadsheet/deleteteam', 'DELETE', data);
}
